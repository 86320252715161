
// @ is an alias to /sr
import {
  defineComponent,
  inject,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import Chip from "@/components/atomics/Chip.vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import AvatarCircle from "@/components/organisms/AvatarCircle.vue";
import Vue3Popper from "@/components/atomics/Vue3Popper.vue";

import { GroupRepository, RepositoryFactory } from "@/lib/https";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "GroupMember",
  components: {
    AfterLoginPageLayout,
    FlatButton,
    Chip,
    BreadScrum,
    AvatarCircle,
    Vue3Popper,
  },
  props: {
    groupID: { type: String, required: true },
    groupName: { type: String, required: true },
  },
  setup(props) {
    const state = reactive({
      breadScrums: [] as Array<any>,
      groupRole: {
        canUpdate: false,
        canDelete: false,
      },
      accountInfor: { id: "", name: "", role: 1, avatar: "" },
    });

    const users = ref([] as Array<any>);
    const selectedUsers = ref([] as Array<any>);
    const isMobile = inject("isMobile");

    const searchKeyword = ref("");
    const searchKeywordTextField = ref("");
    let delay: ReturnType<typeof setTimeout>;
    let page = 1;
    let total = 0;

    const members = ref([] as Array<any>);
    let memberPage = 1;
    let totalMember = ref(0);

    const store = useStore();
    const { t } = useI18n();

    const showSuccessNotification = (text: string) => {
      store.commit("notification/showSuccessNotification", text);
    };
    const showErrorNotification = (text: string) => {
      store.commit("notification/showErrorNotification", text);
    };

    const search = () => {
      if (delay) clearTimeout(delay);
      delay = setTimeout(async () => {
        page = 1;
        const payload = await getUserList({
          groupId: props.groupID,
          page: page,
          keyword: searchKeyword.value,
        });
        users.value = payload.data as Array<any>;
        total = payload.pagination.totalRecords;
      }, 300);
    };

    const loadMoreUsers = async (e: any) => {
      const bottomOfWindow =
        Math.floor(e.target.scrollTop + e.target.offsetHeight) ===
        e.target.scrollHeight;
      let canLoadMore = users.value?.length < total;

      if (bottomOfWindow && canLoadMore) {
        page = page + 1;
        const moreUsers = await getUserList({
          groupId: props.groupID,
          page: page,
          keyword: searchKeyword.value,
        });
        users.value.push(...(moreUsers.data as Array<any>));
      }
    };

    const loadMoreMembers = async () => {
      window.onscroll = async () => {
        const bottomOfWindow =
          Math.floor(
            document.documentElement.scrollTop + window.innerHeight + 1
          ) >= document.documentElement.scrollHeight;
        let canLoadMore = members.value?.length < totalMember.value;

        if (bottomOfWindow && canLoadMore) {
          page = page + 1;
          const moreMembers = await getMemberGroup(
            props.groupID,
            memberPage,
            10
          );
          members.value.push(...moreMembers.data);
        }
      };
    };

    const { getUserList, getMemberGroup, addMembers, deleteMember } =
      groupMemberMethods();

    const addMember = async () => {
      const userList = [] as any;
      selectedUsers.value.forEach((user: any) => {
        userList.push(user.id);
      });
      await addMembers(props.groupID, { members: userList })
        .then((response) => {
          if (response) {
            selectedUsers.value.forEach((user: any) => {
              //add member to member's group
              members.value.push(user);
            });
            selectedUsers.value.forEach((user: any) => {
              // remove from choose list
              const index = users.value.indexOf(user);
              (users.value[index] as any).excludeFlag = true;
            });

            totalMember.value = totalMember.value + selectedUsers.value?.length;
            selectedUsers.value = [];
            showSuccessNotification(t("notification.createSuccess"));
          }
        })
        .catch((e) => {
          showErrorNotification(e.data.message);
        });
    };

    const removeMember = async (user: any) => {
      const response = await deleteMember(props.groupID, user.id).catch((e) => {
        // alert(e.data.message);
        showErrorNotification(e.data.message);
      });
      if (response) {
        members.value.splice(members.value.indexOf(user as any), 1);

        const userIndexOfAllUsers = users.value.findIndex(
          (item: any) => item.id == user.id
        );
        if (userIndexOfAllUsers >= 0)
          (users.value[userIndexOfAllUsers] as any).excludeFlag = false;

        totalMember.value = totalMember.value - 1;
        showSuccessNotification(t("notification.deleteSuccess"));
      }
    };

    onBeforeMount(async () => {
      state.breadScrums = [
        { text: props.groupName, to: "/group" },
        {
          text: t("group.optionEditPermission"),
          to: `?groupID=${props.groupID}&groupName=${props.groupName}`,
        },
      ] as any[];

      const payload = await getUserList({
        groupId: props.groupID,
      });
      users.value = payload.data as Array<any>;
      total = payload.pagination.totalRecords ?? 0;

      const memberList = await getMemberGroup(props.groupID);
      members.value = memberList.data;
      totalMember.value = memberList.pagination.totalRecords ?? 0;

      state.groupRole.canUpdate = memberList.pagination.canUpdate ?? false;
      state.groupRole.canDelete = memberList.pagination.canDelete ?? false;
    });

    const isSelected = (item: any) =>
      selectedUsers.value.find((user: any) => user.id === item.id);

    onMounted(() => {
      loadMoreMembers();
      const accountInfor = store.getters["header/getAccountInfo"];
      state.accountInfor = accountInfor;
    });

    return {
      ...toRefs(state),
      users,
      selectedUsers,
      search,
      searchKeyword,
      searchKeywordTextField,
      loadMoreUsers,
      members,
      totalMember,
      addMember,
      removeMember,
      isSelected,
      isMobile,
    };
  },
  methods: {
    onInput(value: any) {
      this.searchKeyword =
        this.searchKeywordTextField +
        (value.isComposing ? value?.data ?? "" : "");
      this.search();
    },
    userAvatar(url: string) {
      return process.env.VUE_APP_API_CLOUD_URL + url;
    },
    selectUser(index: number) {
      const isContain = this.selectedUsers.find(
        (element: any) => element.id == (this.users[index] as any).id
      );

      if (!isContain) this.selectedUsers.push(this.users[index]);
    },
    removeSelectedUser(index: number) {
      this.selectedUsers.splice(index, 1);
    },
  },
});

const groupMemberMethods = () => {
  const { getUserList, getMemberGroup, addMembers, deleteMember } =
    RepositoryFactory.getRepository<GroupRepository>(GroupRepository);

  return {
    getUserList,
    getMemberGroup,
    addMembers,
    deleteMember,
  };
};
